<template>
  <div>
    <app-header />

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Login</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
         uk-flex uk-flex-middle uk-animation-fade
      "
    >
      <div class="uk-width-1-1 uk-margin-medium-top">
        <div class="uk-container">
          <div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
            <div class="uk-width-1-1@m">
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
                v-if="! email_verification_resent"
              >
                <h3 class="uk-card-title uk-text-center">Welcome back!</h3>
                <div class="uk-alert-danger" uk-alert v-if="has_error">
                  <a class="uk-alert-close" uk-close href="#"></a>
                  <p>Invalid email and/or password</p>
                </div>
                <div class="uk-alert-danger" uk-alert v-if="account_suspended">
                  <p>Your account has been suspended.</p>
                </div>
                <form @submit.prevent="submit">
                  <div class="uk-margin">
                    <label for="">Email</label>
                    <div class="uk-inline uk-width-1-1">
                      <span class="uk-form-icon"
                        ><i
                          class="fa fa-envelope"
                          style="margin-right: 20px"
                        ></i
                      ></span>
                      <input
                        class="uk-input uk-form-large"
                        type="text"
                        required
                        v-model="user.email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <label for="">Password</label>
                    <div class="uk-inline uk-width-1-1">
                      <span class="uk-form-icon"
                        ><i class="fa fa-lock" style="margin-right: 20px"></i
                      ></span>
                      <input
                        class="uk-input uk-form-large"
                        type="password"
                        required
                        v-model="user.password"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <button
                      class="
                        uk-button uk-button-primary uk-button-large uk-width-1-1
                      "
                    >
                      Login
                    </button>
                  </div>
                  <div class="uk-text-small uk-text-center">
                    <router-link :to="{ name: 'register' }"
                      >Don't have an account? Create one</router-link
                    >
                  </div>
                  <div class="uk-text-small uk-text-center">
                    <router-link :to="{ name: 'forgot-password' }"
                      >Forgot your password?</router-link
                    >
                  </div>
                </form>
              </div>
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
                v-if="email_verification_resent"
              >
                <h3>Verify your email</h3>
                <p>We have resent your verification email.</p>
                <p>Click on the link in your email to activate your account.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      has_error: false,
      email_verification_resent: false,
      account_suspended: false
    };
  },

  methods: {
    submit() {
      this.$loader.start("public");
      this.has_error = false;
      this.email_verification_resent = false
      this.account_suspended = false

      this.$axios
        .post("/api/v1/login", this.user)
        .then((response) => {
          this.$auth.setToken(response.data.access_token);
          window.location.href = this.$router.resolve({
            name: "redirect",
          }).href;
        })
        .catch(e => {
          let error = e.response

          if(error.data.message == 0) {
            this.email_verification_resent = true
          } 
          else if (error.data.message == 1) {
            this.account_suspended = true
          }
          else {
            this.has_error = true;
          }

          this.$loader.stop();
        });
    },
  },
};
</script>
